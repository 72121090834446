@import url('https://fonts.googleapis.com/css?family=Titillium+Web:300,400,500,700&display=swap');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.cookie-consent {
  background: #002742!important;
  box-shadow: 0px -3px 4px -1px rgba(0,0,0,0.2), 0px -2px 5px 0px rgba(0,0,0,0.14), 0px -1px 10px 0px rgba(0,0,0,0.12);
  padding: 15px;
  align-items: center!important;
}
.cookie-consent-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .cookie-consent-button {
  font-size: 13px;
  border-radius: inherit!important;
  background: rgb(255, 212, 45);
  border: 0px;
  box-shadow: none;
  color: black;
  cursor: pointer;
  flex: 0 0 auto;
  padding: 5px 10px;
  margin: 15px;
}
.cookie-consent-button.deny {
  background: rgb(193, 42, 42);
  color: rgb(229, 229, 229);
} */
@media (max-width: 580px){
  .cookie-consent-button-wrapper {
    width: 100%;
    padding-top: 10px;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
